// Generated by ReScript, PLEASE EDIT WITH CARE


function serialize(t) {
  return t;
}

function parse(s) {
  switch (s) {
    case "PAGEVIEWS_DESCENDING" :
        return "PAGEVIEWS_DESCENDING";
    case "PUBLISHED_AT_ASCENDING" :
        return "PUBLISHED_AT_ASCENDING";
    case "PUBLISHED_AT_DESCENDING" :
        return "PUBLISHED_AT_DESCENDING";
    case "RELATIVE_VIEWS_DESCENDING" :
        return "RELATIVE_VIEWS_DESCENDING";
    default:
      return ;
  }
}

var $$default = "PUBLISHED_AT_DESCENDING";

export {
  serialize ,
  parse ,
  $$default as default,
}
/* No side effect */
