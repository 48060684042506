// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Clerk from "./clerk/Clerk.mjs";
import * as React from "react";
import * as Button from "../components/ui/Button.mjs";
import * as Sonner from "../components/ui/Sonner.mjs";
import * as Tooltip from "../components/ui/Tooltip.mjs";
import * as RelayEnv from "./RelayEnv.mjs";
import * as AllerLogo from "./components/layout/AllerLogo.mjs";
import * as Core__Null from "@rescript/core/src/Core__Null.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RelayRouter from "rescript-relay-router/src/RelayRouter.mjs";
import * as LucideReact from "lucide-react";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.mjs";
import * as ThemeProvider from "../components/ui/ThemeProvider.mjs";
import * as RouteDeclarations from "./routes/__generated__/RouteDeclarations.mjs";
import * as JsxRuntime from "react/jsx-runtime";
import * as ClerkReact from "@clerk/clerk-react";
import * as RouteErrorBoundary from "./components/RouteErrorBoundary.mjs";
import * as PendingIndicatorBar from "./components/PendingIndicatorBar.mjs";
import * as ReactDOMExperimental from "rescript-relay/src/ReactDOMExperimental.mjs";
import * as WebAnalytics from "@statsig/web-analytics";
import * as ReactBindings from "@statsig/react-bindings";
import * as SessionReplay from "@statsig/session-replay";
import * as RelayRouter__AssetPreloader from "rescript-relay-router/src/RelayRouter__AssetPreloader.mjs";

function EntryClient$SignedOut(props) {
  var $$location = window.location;
  var pathname = $$location.pathname;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: "basis-1/6"
                    }),
                JsxRuntime.jsx(AllerLogo.make, {
                      className: "size-32 "
                    }),
                JsxRuntime.jsx("h1", {
                      children: "Hello, welcome to AllerAI",
                      className: "text-2xl md:text-4xl font-semibold tracking-wide"
                    }),
                JsxRuntime.jsx(ClerkReact.SignInButton, {
                      mode: "modal",
                      forceRedirectUrl: pathname,
                      children: Caml_option.some(JsxRuntime.jsxs(Button.make, {
                                size: "lg",
                                className: "gap-2 px-16",
                                children: [
                                  "Log in with Microsoft",
                                  JsxRuntime.jsx(LucideReact.ChevronRight, {
                                        className: "w-4 h-4"
                                      })
                                ]
                              }))
                    })
              ],
              className: "w-screen h-screen flex flex-col gap-8 items-center bg-muted text-[#353A40]"
            });
}

var SignedOut = {
  make: EntryClient$SignedOut
};

function EntryClient$SignedIn(props) {
  var match = ClerkReact.useAuth();
  var orgRole = match.orgRole;
  var orgId = match.orgId;
  var orgSlug = match.orgSlug;
  var userId = match.userId;
  var match$1 = ClerkReact.useUser();
  var user = match$1.user;
  var api = import.meta.env.VITE_STATSIG_API_URL;
  var tmp = api === "" ? undefined : api;
  var options_environment = {
    tier: import.meta.env.VITE_STATSIG_ENV
  };
  var options_networkConfig = {
    api: tmp,
    preventAllNetworkTraffic: import.meta.env.VITE_STATSIG_PREVENT_NETWORK_TRAFFIC
  };
  var options = {
    environment: options_environment,
    disableLogging: import.meta.env.VITE_STATSIG_DISABLE_LOGGING,
    disableStorage: import.meta.env.VITE_STATSIG_DISABLE_STORAGE,
    networkConfig: options_networkConfig
  };
  var getUserObject = function () {
    return {
            userID: userId,
            email: Core__Null.fromOption(user !== undefined ? user.primaryEmailAddress.emailAddress : undefined),
            custom: {
              organizationRole: orgRole
            },
            customIDs: {
              organizationID: orgId,
              organizationSlug: orgSlug
            }
          };
  };
  var match$2 = ReactBindings.useClientAsyncInit(import.meta.env.VITE_STATSIG_SDK_CLIENT_KEY, getUserObject(), options);
  var client = match$2.client;
  React.useEffect((function () {
          WebAnalytics.runStatsigAutoCapture(client);
          SessionReplay.runStatsigSessionReplay(client);
        }), [client]);
  return JsxRuntime.jsx(ReactBindings.StatsigProvider, {
              client: client,
              children: JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
                    environment: props.environment,
                    children: JsxRuntime.jsx(RelayRouter.Provider.make, {
                          value: props.routerContext,
                          children: JsxRuntime.jsx(React.Suspense, {
                                children: Caml_option.some(JsxRuntime.jsxs(RouteErrorBoundary.make, {
                                          children: [
                                            JsxRuntime.jsx(RelayRouter.RouteRenderer.make, {
                                                  renderPending: (function (pending) {
                                                      return JsxRuntime.jsx(PendingIndicatorBar.make, {
                                                                  pending: pending
                                                                });
                                                    })
                                                }),
                                            JsxRuntime.jsx(Sonner.Toaster.make, {})
                                          ]
                                        })),
                                fallback: Caml_option.some(null)
                              })
                        })
                  })
            });
}

var SignedIn = {
  make: EntryClient$SignedIn
};

function EntryClient$EntryPoint(props) {
  var match = ClerkReact.useAuth();
  var orgId = match.orgId;
  var getToken = match.getToken;
  var match$1 = ClerkReact.useOrganizationList({
        userMemberships: true
      });
  var setActive = match$1.setActive;
  var userMemberships = match$1.userMemberships;
  React.useEffect((function () {
          Clerk.Hooks.Auth.getTokenRef.contents = getToken;
        }), [getToken]);
  React.useEffect((function () {
          var memberships = userMemberships.data;
          if (memberships !== undefined) {
            var match = memberships[0];
            if (match !== undefined && orgId === null) {
              setActive({
                    organization: match.organization
                  });
            }
            
          }
          
        }), [
        userMemberships,
        match.userId,
        orgId
      ]);
  return JsxRuntime.jsx(ThemeProvider.make, {
              children: JsxRuntime.jsxs(Tooltip.Provider.make, {
                    children: [
                      JsxRuntime.jsx(ClerkReact.SignedOut, {
                            children: JsxRuntime.jsx(EntryClient$SignedOut, {})
                          }),
                      JsxRuntime.jsx(ClerkReact.SignedIn, {
                            children: JsxRuntime.jsx(EntryClient$SignedIn, {
                                  environment: props.environment,
                                  routerContext: props.routerContext
                                })
                          })
                    ]
                  })
            });
}

var EntryPoint = {
  make: EntryClient$EntryPoint
};

function boot() {
  var routes = RouteDeclarations.make(undefined);
  var routerEnvironment = RelayRouter.RouterEnvironment.makeBrowserEnvironment();
  var match = RelayRouter.Router.make(routes, routerEnvironment, RelayEnv.environment, RelayRouter__AssetPreloader.makeClientAssetPreloader(RelayEnv.preparedAssetsMap));
  ReactDOMExperimental.renderConcurrentRootAtElementWithId(JsxRuntime.jsx(ClerkReact.ClerkProvider, {
            publishableKey: import.meta.env.VITE_CLERK_PUBLISHABLE_KEY,
            allowedRedirectOrigins: ["https://allerai.featurebase.app/"],
            children: JsxRuntime.jsx(EntryClient$EntryPoint, {
                  environment: RelayEnv.environment,
                  routerContext: match[1]
                })
          }), "root");
}

boot();

export {
  SignedOut ,
  SignedIn ,
  EntryPoint ,
  boot ,
}
/*  Not a pure module */
